function Header() {
  const sendEmail = () => {
    window.location = 'mailto:dmitri.dmitriev@gmx.com'
  }

  return (
    <div className="header">
      <h1 className="header__title">
        Dmitri <br />
        Dmitriev
      </h1>
      <p className="header__subtitle">full stack web developer</p>
      <nav>
        <ul className="nav-items">
          <a href="#projects-section">
            <li className="nav__item">projects</li>
          </a>
          {/* <li className="nav__item">contact</li> */}
          <a href="https://dl.dropboxusercontent.com/s/rhz58ppqi5oe7k6/Dmitri%20Dmitriev%20CV%202022.pdf?dl=0">
            <li className="nav__item">download cv</li>
          </a>
        </ul>
      </nav>
      <div class="header__contacts">
        <button className="header__email" onClick={() => sendEmail()}>
          dmitri.dmitriev@gmx.com
        </button>
        <div className="header__nav-icons">
          <a
            href="https://www.linkedin.com/in/dmitridmitriev/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="header__nav-icon header__nav-icon-linkedin"></button>
          </a>
          <a
            href="https://github.com/chillyweather"
            target="_blank"
            rel="noreferrer"
          >
            <button className="header__nav-icon header__nav-icon-git"></button>
          </a>
          <a
            href="https://www.instagram.com/chilly_weather/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="header__nav-icon header__nav-icon-instagram"></button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header
