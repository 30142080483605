function Main() {
  const uxProject = (link, style, title, about) => {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <div className={`main__ux-project ${style}`}>
          <div className={`main__ux-project-overlay ${style}-overlay`}>
            <h3 className="main__ux-project-title">{title}</h3>
            <h3 className="main__ux-project-about">{about}</h3>
          </div>
        </div>
      </a>
    )
  }

  return (
    <div className="main">
      <p className="main__about">
        Hi. I’m Dmitri. A positive, innovative, results-driven web developer
        with a background in cartography, design, and vast experience in print
        production. I'm currently looking for a position where I could
        constantly learn and fulfill my passion with finding optimal solutions
        for problems.
      </p>
      <h2 className="main__section-title">skills</h2>
      <p className="main__skills">
        JavaScript • React • NodeJS • Express • MongoDB • Mongoose • HTML • CSS
      </p>

      {/* projects */}
      <h2 className="main__section-title" id="projects-section">
        projects
      </h2>

      {/* infostash project */}
      <a href="https://infostash.space/" target="_blank" rel="noreferrer">
        <h3 className="main__project-infostash-info-title">infostash.space</h3>
        <div className="main__project main__project-infostash">
          <div className="main__project-infostash-overlay">
            <div className="main__project-infostash-description">
              <h2 className="main__project-infostash-description-header__title">
                infostash.space
              </h2>
              <p className="main__project-infostash-description-header__content">
                This website allows users to search through recent news articles
                (powered by NewsAPI), and save/remove favorite articles to their
                personal account page (powered by the Node/Express backend API
                here), as well as handling handling account creation and login.
              </p>
              <p className="main__project-infostash-description-header__content">
                <span className="bold">Front-end:</span>
                <br />
                React
              </p>
              <p className="main__project-infostash-description-header__content">
                <span className="bold">Back-end:</span>
                <br />
                NodeJS, Express, MongoDB
              </p>
            </div>
          </div>
        </div>
      </a>
      <p className="main__project-infostash-info-text">
        This website allows users to search through recent news articles
        (powered by NewsAPI), and save/remove favorite articles to their
        personal account page (powered by the Node/Express backend API here), as
        well as handling handling account creation and login.
      </p>
      <p className="main__project-infostash-info-text">Front-end: React</p>
      <p className="main__project-infostash-info-text">
        Back-end: NodeJS, Express, MongoDB
      </p>

      {/* ux portfolio */}
      <h2 className="main__section-title" id="ux-portfolio">
        ui/ux
      </h2>

      <div className="main__ux">
        {uxProject(
          'https://dmitridmitriev.netlify.app/photomemo.html',
          'photomemo',
          'photomemo',
          "photographer's companion app"
        )}
        {uxProject(
          'https://dmitridmitriev.netlify.app/bookdrop.html',
          'bookdrop',
          'bookdrop',
          "let's give books a second life"
        )}
        {uxProject(
          'https://dmitridmitriev.netlify.app/jazz.html',
          'jazz',
          'jazz festival',
          ''
        )}
        {uxProject(
          'https://dmitridmitriev.netlify.app/e-commerce.html',
          'ecommerce',
          'e-commerce website',
          ''
        )}
      </div>
    </div>
  )
}

export default Main
